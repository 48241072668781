import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SEO from '../components/seo';

import ContactFooter from '../components/ContactForm/ContactFooter';
import DienstBox from '../components/DienstBox';

import maxWidth from '../components/styles/maxWidth';

const ServicesPage = () => {

    const data = useStaticQuery(graphql`
    query dienstenQuery {
        prismicDiensten {
            data {
                titel {
                    text
                }
                intro_text {
                    html
                }
            }
        }
        allPrismicDienstI (sort: {fields: id, order: ASC}) {
            edges {
                node {
                    uid
                }
            }
        }
        prismicBasicInfo {
            data {
                orange
                main_color_two
            }
        }
    }
    `);

    const { titel, intro_text } = data.prismicDiensten.data;
    const { edges } = data.allPrismicDienstI;
    const { orange, main_color_two } = data.prismicBasicInfo.data;

    const mainStyle = {
        backgroundColor: main_color_two,
    }

    const sectionStyle = {
        maxWidth: maxWidth, 
        margin: '0 auto' 
    }

    const headerStyle = {
        textAlign: 'center',
        padding: 25
    }

    const dienstSectionStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        paddingBottom: 67,
        maxWidth: 1200,
        margin: '0 auto'
    }

    return (
        <>
            <SEO title={titel.text} />

            <main className='borderradius-div-bottom' style={mainStyle}>
                <section style={sectionStyle}>
                    <h1 className='resp-header' style={headerStyle}>{titel.text}</h1>
                    <section className='resp-extra-padding' style={{maxWidth: maxWidth * .8, margin: '0 auto'}} dangerouslySetInnerHTML={{__html: intro_text.html}}/>              
                </section>

                <section style={dienstSectionStyle}>
                    {edges.map((n, i) => <DienstBox key={i} dienstID={n.node.uid} bgColor={orange} />)}
                </section>
                
            </main>

            <ContactFooter />
        </>
    )
}

export default ServicesPage;