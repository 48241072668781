import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Link from "../components/HelperComponents/TransitionLink"

const DienstBox = ({ dienstID, bgColor }) => {
  const data = useStaticQuery(graphql`
    query dienstBoxQuery {
      allPrismicDienstI {
        edges {
          node {
            uid
            data {
              naam_dienst {
                text
              }
              korte_omschrijving {
                text
              }
              logo {
                localFile {
                  childImageSharp {
                    fixed(width: 50) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
      prismicBasicInfo {
        data {
          main_color_three
        }
      }
    }
  `)

  const { main_color_three } = data.prismicBasicInfo.data

  const [node] = data.allPrismicDienstI.edges.filter(
    n => n.node.uid === dienstID
  )

  const { naam_dienst, logo, korte_omschrijving } = node.node.data

  return (
    <Link
      to={`/diensten/${node.node.uid}`}
      linkStyle={{
        display: "inline-flex",
        flexDirection: "column",
        color: main_color_three,
        textDecoration: "none",
        backgroundColor: bgColor,
        padding: 20,
        paddingBottom: 0,
        borderRadius: "5px 5px 50px 5px",
        margin: 40,
        width: "100%",
        maxWidth: 300,
        minHeight: 235,
      }}
    >
      <section
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginBottom: 15,
        }}
      >
        <section style={{ width: 50, marginRight: 15 }}>
          <Img fixed={logo.localFile.childImageSharp.fixed} />
        </section>
        <h4 style={{ display: "inline", marginBottom: 0 }}>
          {naam_dienst.text}
        </h4>
      </section>
      <p>{korte_omschrijving.text}</p>
      <p style={{ fontSize: "85%", marginTop: "auto" }}>Klik voor meer info</p>
    </Link>
  )
}

export default DienstBox
